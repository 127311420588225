export interface IComponentGroup {
  id: string;
  name: string;
}

export interface IComponentType {
  // Уникальное, техническое наименование (a-label, a-string и тд)
  id: string;
  // Наименование (надпись)
  name: string;
  // Описание компонента (подсказка)
  description: string | null;
  // Иконка компонента
  icon: string | null;
  // Группа компонента (Аналитика, Диаграммы, Реестровые, Базовые и тд)
  group: string;
  // Vue компонент, который рендерится в карточке
  vueComponent: string;
  // Массив свойств доступных компоненту (ссылка на componentProperties[index].id)
  properties: string[];
}

export const componentTypes: IComponentType[] = []

export const componentGroups: IComponentGroup[] = [
  { id: 'bases', name: 'interface_editor.bases' },
  { id: 'charts', name: 'interface_editor.charts' },
  { id: 'tables', name: 'interface_editor.tables' },
  { id: 'others', name: 'interface_editor.others' },
  { id: 'registryFields', name: 'interface_editor.registryFields' }
]
